import { utils } from "ethers";
import { z } from "zod";

export const addressTransformer = z
  .string()
  .startsWith("0x")
  .transform((v) => utils.getAddress(v));

export const statusValidator = z.enum([
  "pending",
  "processing",
  "completed",
  "failed",
]);

export const statusScoreValidator = z.enum(["1", "2", "3", "4"]);

export const webhookValidator = z
  .object({
    url: z.string().url(),
    meta: z.any().optional(),
  })
  .passthrough();

export const sendEmailValidator = z.object({
  to: z.union([z.string().email(), z.string().email().array()]),
  subject: z.string(),
  template: z.enum([
    "welcome",
    "ticketPaymentReceived",
    "ticketReceived",
    "confirmationEmail",
    "collaboratorInternalEmail",
    "ticketCourtesy",
    "communication",
  ]),
  props: z.any(),
});

export const userProviderValidator = z.string().transform((val) => {
  if (val === "google" || val === "facebook" || val === "injected") {
    return val;
  }
  if (val === "email_passwordless") {
    return "email";
  }
  throw new Error("Invalid UserProvider");
});

export const sendBatchEmailValidator = z.object({
  emails: sendEmailValidator.array(),
  eventId: z.number().int().optional(),
});
