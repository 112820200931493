// @ts-check
import { z } from "zod";

/**
 * Specify your server-side environment variables schema here.
 * This way you can ensure the app isn't built with invalid env vars.
 */
export const serverSchema = z.object({
  NODE_ENV: z.preprocess((val) => {
    if (process.env.VERCEL_BRANCH_URL?.includes("dev-spaceship") && val) {
      return "staging";
    }
    return val;
  }, z.enum(["development", "test", "production", "staging"])),
  COINMARKETCAP_API_KEY: z.string(),
  NEXTAUTH_SECRET:
    process.env.NODE_ENV === "production"
      ? z.string().min(1)
      : z.string().min(1).optional(),
  NEXTAUTH_URL: z.preprocess(
    // This makes Vercel deployments not fail if you don't set NEXTAUTH_URL
    // Since NextAuth automatically uses the VERCEL_URL if present.
    (str) => {
      if (typeof str !== "string") throw new Error("Bad variable");
      const includes = ["dev-spaceship", "master-spaceship"];
      const branchUrl = process.env.VERCEL_BRANCH_URL ?? "";
      if (includes.some((url) => branchUrl.includes(url))) {
        return str;
      }
      return process.env.VERCEL_BRANCH_URL
        ? `https://${process.env.VERCEL_BRANCH_URL}`
        : str;
    },
    // VERCEL_URL doesnt include `https` so it cant be validated as a URL
    process.env.VERCEL ? z.string() : z.string().url()
  ),
  SEED:
    process.env.NEXT_PUBLIC_BLOCKCHAIN_ENV !== "test"
      ? z.string()
      : z.string().default(""),
  SENDGRID_API_KEY: z.string().optional(),
  POSTMARK_API_KEY: z.string().optional(),
  GOOGLE_ACCOUNT: z.string().optional(),
  GOOGLE_PASSWORD: z.string().optional(),
  REDIS_HOST: z.string().default("127.0.0.1"),
  REDIS_PORT: z.number({ coerce: true }).int().positive().default(6379),
  REDIS_PASSWORD: z.string().default("default"),
  REDIS_USER: z.string().default("default"),
  STRIPE_SECRET_KEY: z.string(),
  STRIPE_WEBHOOK_SECRET: z.string(),
  ASAAS_TOKEN_CONFIRMATION: z.string(),
  ASAAS_URL: z.string(),
  ASAAS_API_KEY: z.string(),
});

/**
 * Specify your client-side environment variables schema here.
 * This way you can ensure the app isn't built with invalid env vars.
 * To expose them to the client, prefix them with `NEXT_PUBLIC_`.
 */
export const clientSchema = z.object({
  // NEXT_PUBLIC_BAR: z.string(),
  NEXT_PUBLIC_BLOCKCHAIN_ENV: z.enum([
    "development",
    "test",
    "production",
    "staging",
  ]),
  NEXT_PUBLIC_IPFS_PROJECT_ID: z.string(),
  NEXT_PUBLIC_IPFS_SECRET: z.string(),
  NEXT_PUBLIC_ALCHEMY_KEY: z.string(),
  NEXT_PUBLIC_WEB3AUTH_CLIENT_ID: z.string(),
  NEXT_PUBLIC_WEB3AUTH_NETWORK: z.enum([
    "testnet",
    "mainnet",
    "sapphire_mainnet",
    "cyan",
    "sapphire_devnet",
  ]),
  NEXT_PUBLIC_STRIPE_PUBLISHABLE_KEY: z.string(),
  NEXT_PUBLIC_POSTHOG_KEY: z.string(),
  NEXT_PUBLIC_POSTHOG_HOST: z.string(),
  NEXT_PUBLIC_SENTRY_DNS: z.string().url(),
  NEXT_PUBLIC_SENTRY_PROJECT_ID: z.string(),
  NEXT_PUBLIC_SENTRY_ORG_URL: z.string().url(),
});

/**
 * You can't destruct `process.env` as a regular object, so you have to do
 * it manually here. This is because Next.js evaluates this at build time,
 * and only used environment variables are included in the build.
 * @type {{ [k in keyof z.infer<typeof clientSchema>]: z.infer<typeof clientSchema>[k] | undefined }}
 */
export const clientEnv = {
  // NEXT_PUBLIC_BAR: process.env.NEXT_PUBLIC_BAR,
  NEXT_PUBLIC_IPFS_PROJECT_ID: process.env.NEXT_PUBLIC_IPFS_PROJECT_ID,
  NEXT_PUBLIC_IPFS_SECRET: process.env.NEXT_PUBLIC_IPFS_SECRET,
  NEXT_PUBLIC_ALCHEMY_KEY: process.env.NEXT_PUBLIC_ALCHEMY_KEY,
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  NEXT_PUBLIC_BLOCKCHAIN_ENV:
    process.env.NEXT_PUBLIC_BLOCKCHAIN_ENV ?? "production",
  NEXT_PUBLIC_WEB3AUTH_CLIENT_ID: process.env.NEXT_PUBLIC_WEB3AUTH_CLIENT_ID,
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  NEXT_PUBLIC_WEB3AUTH_NETWORK:
    process.env.NEXT_PUBLIC_WEB3AUTH_NETWORK ?? "mainnet",
  NEXT_PUBLIC_STRIPE_PUBLISHABLE_KEY:
    process.env.NEXT_PUBLIC_STRIPE_PUBLISHABLE_KEY,
  NEXT_PUBLIC_POSTHOG_KEY: process.env.NEXT_PUBLIC_POSTHOG_KEY,
  NEXT_PUBLIC_POSTHOG_HOST: process.env.NEXT_PUBLIC_POSTHOG_HOST,
  NEXT_PUBLIC_SENTRY_DNS: process.env.NEXT_PUBLIC_SENTRY_DNS,
  NEXT_PUBLIC_SENTRY_PROJECT_ID: process.env.NEXT_PUBLIC_SENTRY_PROJECT_ID,
  NEXT_PUBLIC_SENTRY_ORG_URL: process.env.NEXT_PUBLIC_SENTRY_ORG_URL,
};
